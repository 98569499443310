import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index.js";

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`);
}

const routes = [
  {
    path: "/",
    name: "Heligonková Akadémia",
    component: () => import("../views/DomovStranka.vue"),
  },
  {
    path: "/naucne-videa",
    name: "Náučné videá",
    component: () => import("../views/shop/NaucneVidea.vue"),
  },
  {
    path: "/ciselne-zapisy",
    name: "Číselné zápisy",
    component: () => import("../views/shop/CiselneZapisy.vue"),
    props: true,
  },
  {
    path: "/ucebna/ciselny-zapis",
    name: "Číselný zápis",
    component: () => import("../views/logged/ucebna/CiselnyZapis.vue"),
    props: true,
  },
  {
    path: "/miesta-vyucby",
    name: "Miesta výučby",
    component: () => import("../views/info/MiestaVyucby.vue"),
  },
  {
    path: "/zistit-viac",
    name: "Zistiť viac",
    component: () => import("../views/info/ZistitViac.vue"),
  },
  {
    path: "/helifest",
    name: "Helifest",
    component: () => import("../views/shop/HelifestStranka.vue"),
  },
  {
    path: "/merch",
    name: "Merch",
    component: () => import("../views/shop/MerchStranka.vue"),
  },
  {
    path: "/o-nas",
    name: "O nás",
    component: () => import("../views/info/ONas.vue"),
  },
  {
    path: "/o-nas/juraj-kvocka",
    name: "Jurak Kvočka",
    component: () => import("../views/about/JurajKvocka.vue"),
  },
  {
    path: "/o-nas/andrej-trnka",
    name: "Andrej Trnka",
    component: () => import("../views/about/AndrejTrnka.vue"),
  },
  // {
  //   path: "/o-nas/matus-mahut",
  //   name: "Matúš Mahút",
  //   component: () => import("../views/about/MatusMahut.vue"),
  // },
  {
    path: "/o-nas/libor-hlinik",
    name: "Libor Hliník",
    component: () => import("../views/about/LiborHlinik.vue"),
  },
  {
    path: "/o-nas/matej-kondela",
    name: "Matej Kondela",
    component: () => import("../views/about/MatejKondela.vue"),
  },
  {
    path: "/pomoc",
    name: "Pomoc",
    component: () => import("../views/info/PomocStranka.vue"),
  },
  {
    path: "/obchodne-podmienky",
    name: "Obchodné Podmienky",
    component: () => import("../views/info/ObchodnePodmienky.vue"),
  },
  {
    path: "/uspesne-odoslanie-formularu",
    name: "Úspešné odoslanie formuláru",
    component: () => import("../views/others/UspesneOdoslanieFormularu.vue"),
  },
  {
    path: "/caste-otazky",
    name: "Časté otázky",
    component: () => import("../views/info/CasteOtazky.vue"),
  },
  {
    path: "/kosik",
    name: "Košík",
    component: () => import("../views/payment/KosikStranka.vue"),
  },
  {
    path: "/pokladna",
    name: "Pokladňa",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/payment/PokladnaStranka.vue"),
  },
  {
    path: "/registracia",
    name: "Registrácia",
    component: () => import("../views/login/RegistraciaStranka.vue"),
  },
  {
    path: "/prihlasenie",
    name: "Prihlásenie",
    component: () => import("../views/login/PrihlasenieStranka.vue"),
  },
  {
    path: "/zabudnute-heslo",
    name: "Zabudnuté heslo",
    component: () => import("../views/login/ZabudnuteHeslo.vue"),
  },
  {
    path: "/zabudnute-heslo-uspesne",
    name: "Zabudnuté heslo úspešné",
    component: () => import("../views/others/ZabudnuteHesloUspesne.vue"),
  },
  {
    path: "/vytvorenie-hesla",
    name: "Vytvorenie hesla",
    component: () => import("../views/login/VytvorenieHesla.vue"),
  },
  {
    path: "/potrebne-prihlasenie",
    name: "Potrebné prihlásenie",
    component: () => import("../views/others/PotrebnePrihlasenie.vue"),
  },
  {
    path: "/ucebna",
    name: "Moja učebňa",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/logged/ucebna/MojUcet.vue"),
  },
  {
    path: "/ucebna/helicas",
    name: "Heličas",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/logged/ucebna/HeliCas.vue"),
  },
  {
    path: "/ucebna/zadarmo-video",
    name: "Zadarmo Video",
    component: lazyLoad("logged/ucebna/ZadarmoVideo"),
  },
  {
    path: "/ucebna/moje-nakupy",
    name: "Moje nákupy",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/logged/ucebna/MojeNakupy.vue"),
  },
  {
    path: "/ucebna/nastavenia-uctu",
    name: "Nastevenia účtu",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/logged/ucebna/NastaveniaUctu.vue"),
  },
  {
    path: "/ucebna/clenstvo",
    name: "Predplatne",
    meta: {
      requiresAuth: true,
    },
    params: true,
    component: () => import("../views/logged/ucebna/ClenstvoPredplatne.vue"),
  },
  {
    path: "/ucebna/clenstvo-zakupene",
    name: "Predplatne zakúpené",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/logged/ucebna/ClenstvoZakúpene.vue"),
  },

  {
    path: "/ucebna/moje-piesne",
    name: "Moje piesne",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/logged/MojePiesne.vue"),
  },
  {
    path: "/ucebna/moje-kurzy",
    name: "Moje kurzy",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/logged/MojeKurzy.vue"),
  },
  {
    path: "/ucebna/naucne-videa",
    name: "Náučné videá zoznam",
    meta: {
      isSubscribe: true,
    },
    params: true,
    component: () => import("../views/logged/ucebna/NaucneVidea.vue"),
  },
  {
    path: "/ucebna/naucne-video",
    name: "Náučné video",
    meta: {
      isSubscribe: true,
    },
    props: true,
    component: () => import("../views/shop/NaucneVideo.vue"),
  },
  {
    path: "/admin/upravenie-pouzivatela",
    name: "Upravenie používateľa",
    meta: {
      isAdmin: true,
    },
    component: () =>
      import("../views/admin/pouzivatelia/UpraveniePouzivatela.vue"),
  },
  {
    path: "/pripravujeme",
    name: "Pripravujeme",
    component: () => import("../views/others/PripravujemeStranka.vue"),
  },
  {
    path: "/uspesny-nakup",
    name: "Úspešný nákup",
    component: () => import("../views/others/UspesnyNakup.vue"),
  },
  {
    path: "/neuspesny-nakup",
    name: "Neúspešný nákup",
    component: () => import("../views/others/NeuspesnyNakup.vue"),
  },
  {
    path: "/admin/reklamny-email",
    name: "Reklamný email",
    meta: {
      isAdmin: true,
    },
    component: () => import("../views/admin/emaily/ReklamnyEmail.vue"),
  },
  {
    path: "/admin/pridanie-merchu",
    name: "Pridanie merchu",
    meta: {
      isAdmin: true,
    },
    component: () => import("../views/admin/pridavanie/PridanieMerchu.vue"),
  },
  {
    path: "/admin/vytvorenie-zapisu",
    name: "Vytvorenie zapisu",
    meta: {
      isAdmin: true,
    },
    component: () => import("../views/admin/pridavanie/VytvorenieZapisu.vue"),
  },
  {
    path: "/admin/editor-zapisov",
    name: "Editor zapisov",
    meta: {
      isAdmin: true,
    },
    component: () => import("../views/admin/zoznamy/EditorZapisov.vue"),
  },
  {
    path: "/admin/pridanie-videa",
    name: "Pridanie videa",
    meta: {
      isAdmin: true,
    },
    component: () => import("../views/admin/pridavanie/PridanieVidea.vue"),
  },
  {
    path: "/admin/pridanie-zapisu",
    name: "Pridanie zápisu",
    meta: {
      isAdmin: true,
    },
    component: () => import("../views/admin/pridavanie/PridanieZapisu.vue"),
  },
  {
    path: "/admin/zoznam-merchu",
    name: "Zoznam Merchu",
    meta: {
      isAdmin: true,
    },
    component: () => import("../views/admin/zoznamy/ZoznamMerchu.vue"),
  },
  {
    path: "/admin/zoznam-predplatitelov",
    name: "Zoznam predplatitelov",
    meta: {
      isAdmin: true,
    },
    component: () => import("../views/admin/zoznamy/ZoznamPredplatitelov.vue"),
  },
  {
    path: "/admin/zoznam-faktur",
    name: "Zoznam faktúr",
    meta: {
      isAdmin: true,
    },
    component: () => import("../views/admin/zoznamy/ZoznamFaktur.vue"),
  },
  {
    path: "/admin/zoznam-uzivatelov",
    name: "Zoznam uživateľov",
    meta: {
      isAdmin: true,
    },
    component: () => import("../views/admin/zoznamy/ZoznamUzivatelov.vue"),
  },
  {
    path: "/admin/zoznam-videi",
    name: "Zoznam videi",
    meta: {
      isAdmin: true,
    },
    component: () => import("../views/admin/zoznamy/ZoznamVidei.vue"),
  },
  {
    path: "/admin/zoznam-zapisov",
    name: "Zoznam zápisov",
    meta: {
      isAdmin: true,
    },
    component: () => import("../views/admin/zoznamy/ZoznamZapisov.vue"),
  },
  {
    path: "/admin/predplatne",
    name: "Predplatné",
    meta: {
      isAdmin: true,
    },
    component: () => import("../views/admin/predplatne/PredplatneVidea.vue"),
  },
  {
    path: "/admin/zlavy",
    name: "Zľavy",
    meta: {
      isAdmin: true,
    },
    component: () => import("../views/admin/zlavy/ZlavoveKody.vue"),
  },
  {
    path: "/admin/",
    name: "Domov",
    meta: {
      isAdmin: true,
    },
    component: () => import("../views/admin/DomovStranka.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/server/404View.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  //ak sa snažím dostať do prihlásenia a som prihlásení prelinkuje na aktuálnu stránku na ktorej sa nachádzam
  if (
    (to.path === "/prihlasenie" && store.state.user != null) ||
    (to.path === "/registracia" && store.state.user != null)
  ) {
    //tu ↓
    store.state.SnackBarText = "Už ste prihlásený";
    next(false);
    return;
  }

  if (
    //ak sa nachádza pri route componentu meta popisok a niesom prihlásený prelinkuje ma
    to.matched.some((record) => record.meta.requiresAuth) &&
    store.state.user == null
  ) {
    // tu ↓
    next("/potrebne-prihlasenie");
    return;
  }

  if (
    //ak sa nachádza pri route componentu meta popisok isSubscribe a nieje tak prelinku
    (to.matched.some((record) => record.meta.isSubscribe) &&
      !store.state.user == null) ||
    (to.matched.some((record) => record.meta.isSubscribe) &&
      !store.state.user?.isSubscribed)
  ) {
    // tu ↓
    next("/ucebna/clenstvo");
    return;
  }

  // Overte, či používateľ má administrátorské oprávnenia
  if (
    (to.matched.some((record) => record.meta.isAdmin) &&
      store.state.user == null) ||
    (to.matched.some((record) => record.meta.isAdmin) &&
      !store.state.user?.isAdmin)
  ) {
    next("/"); // alebo inú cestu, kam chcete presmerovať neautorizovaných administrátorov
    return;
  }

  next();
});

//loading medzi podstránkami

router.beforeEach((to, from, next) => {
  console.log(to, from);
  store.state.isLoaded = false;

  // Pokračujte v navigácii
  next();
});

router.afterEach((to, from) => {
  console.log(to, from);
  store.state.isLoaded = true;
});

export default router;
