import { createStore } from "vuex";
import router from "../router";

export default createStore({
  state: {
    user: null,
    userData: { roles: [] },
    prihlasenyStav: 0,
    userCart: [],
    api: "https://heligonkovaakademia.sk/api/",
    SnackBarText: "",
    isLoaded: false,
    ciselnyZapisObrazky: [],
    overlayVisible: false,
  },
  getters: {},
  mutations: {
    SET_USER(state, userData) {
      state.user = userData;

      if (state.user.ads == "true") {
        state.user.ads = true;
      } else {
        state.user.ads = false;
      }
    },
    setUserData(state, payload) {
      state.userData.roles = payload; // Aktualizácia state
    },

    ADD_USER_INFO_VLASTNENE_ZAPISY(state, notesData) {
      // Pridajte nové údaje do existujúcej vlastnosti
      state.user.ownedNotes = notesData;
    },
    ADD_USER_INFO_KOSIK(state, cartData) {
      // Pridajte nové údaje do existujúcej vlastnosti
      state.userCart = cartData;
    },
    DELETE_USER_INFO_KOSIK(state) {
      state.user = [];
    },
    ADD_USER_ADMIN(state) {
      state.user.isAdmin = true;
    },
    ADD_USER_ACTIVE_SUBSCRIPTION(state) {
      state.user.isSubscribed = true;
    },
    REMOVE_USER_ACTIVE_SUBSCRIPTION(state) {
      state.user.isSubscribed = false;
      console.log(JSON.stringify(state.user));
    },
  },
  actions: {
    prihlasenie({ state }, { email, heslo }) {
      this.dispatch("odhlasenie");
      setTimeout(() => {
        const axios = require("axios");
        const FormData = require("form-data");
        let data = new FormData();
        data.append("email", email);
        data.append("password", heslo);

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: state.api + "/user/auth/logIn.php",
          // headers: {
          //   ...data.getHeaders(),
          // },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            setTimeout(() => {
              switch (response.data.status) {
                case "Request fullfiled":
                  // Úspešne prihlásený na účet
                  state.SnackBarText = "Úspešne prihlásený na účet";
                  this.dispatch("overeniePrihlasenia");
                  router.push("/");
                  return;
                case "Request failed":
                  switch (response.data.data) {
                    case "Already logged in":
                      // Úž je prihlásený na nejaký účet
                      state.SnackBarText = "Už ste prihlásený";
                      return;
                    case "Wrong password":
                      // Nesprávne heslo
                      state.SnackBarText = "Nesprávne heslo";
                      return;
                    case "Email not found":
                      // Účet s týmto emailom neexistuje
                      state.SnackBarText = "Účet s týmto emailom neexistuje";
                      return;
                    case "Trying too often":
                      // Antispam
                      state.SnackBarText = "Antispam: Príliš často sa pokúšate";
                      return;
                    default:
                      // Ďalšie možné stavy, ktoré môžu byť spracované
                      state.SnackBarText = "Neznáma chyba pri prihlásení";
                      return;
                  }
                case "Invalid Request":
                  // Spracovanie scenárov neplatného požiadavku
                  if (response.data.dovod == "") {
                    state.SnackBarText = "Neboli vyplnené všetky povinné polia";
                  } else if (response.data.dovod != "") {
                    state.SnackBarText = "Vyplňte všetky polia formuláru";
                  } else {
                    // Ďalšie spracovanie neplatného požiadavku
                    state.SnackBarText =
                      "Neznámy dôvod chyby. Prosím skúste neskor.";
                  }
                  return;
                default:
                  // Ďalšie možné stavy, ktoré môžu byť spracované
                  state.SnackBarText =
                    "Neznáma chyba pri prihlásení. Prosím skúste neskôť";
                  return;
              }
            }, 100);
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }, 400);
    },
    registracia({ state }, { meno, priezvisko, email, heslo, ads }) {
      const axios = require("axios");
      const FormData = require("form-data");
      let data = new FormData();
      data.append("name", meno);
      data.append("surname", priezvisko);
      data.append("email", email);
      data.append("password", heslo);
      data.append("ads", ads);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: state.api + "/user/auth/register.php",
        // headers: {
        //   ...data.getHeaders(),
        // },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setTimeout(() => {
            switch (response.data.status) {
              case "Request fullfiled":
                state.SnackBarText = "Účet bol úspešne vytvorený";
                // Ďalšie spracovanie úspešného scenára, ak je potrebné
                this.dispatch("prihlasenie", {
                  email: email,
                  heslo: heslo,
                });
                router.push("/");
                return;
              case "Request failed":
                switch (response.data.data) {
                  case "Email already registered":
                    state.SnackBarText = "E-mail už je zaregistrovaný";

                    return;
                  case "":
                    state.SnackBarText =
                      "Chyba pripojenia PHP na DB server. Prosím kontaktujte správcu";
                    return;
                  case "Trying too often":
                    state.SnackBarText =
                      "Antispam: Príliš často sa pokúšate prihlásiť";
                    return;
                  default:
                    // Ďalšie spracovanie neúspešných scenárov
                    state.SnackBarText =
                      "Niečo sa pokazilo. Prosím skúste neskôr";
                    return;
                }
              case "Invalid Request":
                // Spracovanie scenárov neplatného požiadavku
                if (response.data.dovod == "") {
                  state.SnackBarText = "Neboli vyplnené všetky povinné polia";
                } else if (response.data.dovod != "") {
                  state.SnackBarText = "Polia tela neboli správne vyplnené";
                } else {
                  // Ďalšie spracovanie neplatného požiadavku
                  state.SnackBarText =
                    "Niešo sa pokazilo. Prosím skúste neskôr";
                }
                return;
              default:
                // Ďalšie spracovanie rôznych stavov
                state.SnackBarText = "Niešo sa pokazilo. Prosím skúste neskôr";
                return;
            }
          }, 100);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    overeniePrihlasenia({ state, commit }) {
      //zistenie či je používateľ prihláseny a stiahnutie dodatočných informácii
      const axios = require("axios");

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: state.api + "/user/auth/isLoggedIn.php",
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          setTimeout(() => {
            switch (response.data.status) {
              case "Request fullfiled":
                state.prihlasenyStav++;
                this.dispatch("pouzivatelskeInformacie");
                setTimeout(() => {
                  this.dispatch("pouzivatelAktivnePredplatne");
                  this.dispatch("pouzivatelVlastneneProdukty");
                  this.dispatch("pouzivatelskyKosik");
                }, 100);
                setTimeout(() => {
                  if (response.data.data == "Is logged in as admin") {
                    commit("ADD_USER_ADMIN");
                  }
                }, 1000);
                return;
              case "Request failed":
                switch (response.data.data) {
                  case "Not logged in":
                    return;
                  case "Trying too often":
                    return;
                  default:
                    // alert("Neznáma odpoveď");
                    return;
                }
              default:
                // alert("Neznáma odpoveď");
                return;
            }
          }, 100);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pouzivatelskeInformacie({ commit, state }) {
      const axios = require("axios");

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: state.api + "/user/info/getAdditionalInformation.php",
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          switch (response.data.status) {
            case "Request succesfull":
              commit("SET_USER", response.data.data);
              console.log(JSON.stringify(response.data.data));
              state.prihlasenyStav++;
              return;
            case "Request failed":
              switch (response.data.data) {
                case "Email not found / Multiple found":
                  state.SnackBarText =
                    "Email neexistuje (účet bol vymazaný) alebo existuje viacero rovnakých emailov.";
                  return;

                case "Not logged in":
                  return;

                case "Trying too often":
                  state.SnackBarText = "Antispam: pokúšate sa príliš často";
                  return;
                default:
                  // alert("Neznáma odpoveď");
                  return;
              }
            default:
              // alert("Neznáma odpoveď");
              return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pouzivatelAktivnePredplatne({ commit, state }) {
      const axios = require("axios");

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: state.api + "/subscription/isSubscribed.php/",
        // headers: { }
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          if (response.data.status == "Request succesfull") {
            if (response.data.data == "Is subscribed") {
              commit("ADD_USER_ACTIVE_SUBSCRIPTION");
              console.log("odberá");
            } else if (response.data.data == "Is not subscribed") {
              commit("REMOVE_USER_ACTIVE_SUBSCRIPTION");
              console.log(" neodoberá");
            }

            state.prihlasenyStav++;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetovanieHesla({ state }, email) {
      const axios = require("axios");
      const FormData = require("form-data");
      let data = new FormData();
      data.append("email", email);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: state.api + "/user/auth/forgotPasswordSendEmail.php",
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          switch (response.data.status) {
            case "Request fullfiled":
              console.log(JSON.stringify(response.data.data));
              state.SnackBarText = "Obnovovací email bol zaslaný.";
              router.push("/zabudnute-heslo-uspesne");
              return;
            case "Request failed":
              switch (response.data.data) {
                case "Email not found":
                  state.SnackBarText =
                    "Zadaný email sa nenašiel. Prosím skontrolujte si Vašu emailovú adresu.";
                  return;

                case "Trying too often":
                  state.SnackBarText =
                    "Email bol odoslaný. Další email bude možné odoslať za 1 minútu.";
                  return;
                default:
                  state.SnackBarText =
                    "Niečo sa pokazilo. Ak problém pretrváva prosím kontaktujte nás.";
                  return;
              }
            case "Invalid request":
              state.SnackBarText = "Prosím vyplnte všetky povinné polia";
              return;
            default:
              state.SnackBarText =
                "Niečo sa pokazilo. Ak problém pretrváva prosím kontaktujte nás.";
              return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    odhlasenie({ state }) {
      const axios = require("axios");

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: state.api + "/user/auth/logOut.php",
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          switch (response.data.data) {
            case "":
              // Úž je prihlásený na nejaký účet
              state.SnackBarText = "Boli ste úspešne odhlásení";
              state.user = null;
              router.push("/");
              return;
            case "Not logged in":
              // Nesprávne heslo
              // state.SnackBarText = "Pre odhlásenie musíte byť prihlásený";
              return;
            case "Trying too often":
              // Antispam
              state.SnackBarText = "Antispam: Príliš často sa pokúšate";
              return;
            default:
              // Ďalšie možné stavy, ktoré môžu byť spracované
              state.SnackBarText = "Neznáma chyba pri odhlasovaní";
              return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    pouzivatelskyKosik({ commit, state }) {
      const axios = require("axios");

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: state.api + "/cart/load.php",
        // headers: {
        // }
      };

      axios
        .request(config)
        .then((response) => {
          setTimeout(() => {
            console.log(JSON.stringify(response.data));
            switch (response.data.status) {
              case "Request fullfiled":
                commit("ADD_USER_INFO_KOSIK", response.data.data);
                state.prihlasenyStav++;
                return;
              case "Request failed":
                switch (response.data.data) {
                  case "Not logged in":
                    console.log(response.data.data);
                    return;
                  case "Trying too often":
                    state.SnackBarText = "Váš košík nebol načítaný správne";
                    return;
                  default:
                    // alert("Neznáma odpoveď");
                    state.SnackBarText = "Váš košík nebol načítaný správne";
                    return;
                }
              default:
                // alert("Neznáma odpoveď");
                state.SnackBarText = "Váš košík nebol načítaný správne";
                return;
            }
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pridajDoKosika({ state }, id) {
      const axios = require("axios");

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: state.api + "/cart/add.php/?id=" + id,
        // headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          switch (response.data.status) {
            case "Request fullfiled":
              this.dispatch("pouzivatelskyKosik");
              state.SnackBarText = "Produkt bol pridaný do košíka";
              return;
            case "Request failed":
              if (response.data.data == "Not logged in") {
                state.SnackBarText =
                  "Pre pridanie produktu do košíka musíte byť prihlásený";
              } else if (response.data.data == "Already in cart")
                state.SnackBarText = "Produkt sa už nachádza vo vašom košíku";
              else {
                state.SnackBarText = "Nepodarilo sa vložiť produkt do košíka";
              }
              return;
            default:
              state.SnackBarText = "Nepodarilo sa vložiť produkt do košíka";
              return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    odstranZKosiku({ state }, id) {
      const axios = require("axios");

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: state.api + "/cart/remove.php/?id=" + id,
        // headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          switch (response.data.status) {
            case "Request fullfiled":
              console.log("response.data :>> ", response.data);
              this.dispatch("pouzivatelskyKosik");
              state.SnackBarText = "Produkt bol odstránení s košíka";
              return;
            default:
              state.SnackBarText = "Nepodarilo sa odstrániť produkt";
              return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    vytvorPlatbu({ state }) {
      const axios = require("axios");

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: state.api + "/cart/paymentCreate.php",
        // headers: {
        // }
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          window.location.href = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    pouzivatelVlastneneProdukty({ commit, state }) {
      const axios = require("axios");

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: state.api + "/product/getOwned.php",
        // headers: {
        // }
      };

      axios
        .request(config)
        .then((response) => {
          switch (response.data.status) {
            case "Request succesfull":
              if (response.data.data.length > 0) {
                commit("ADD_USER_INFO_VLASTNENE_ZAPISY", response.data.data);
                console.log(JSON.stringify(state.user) + "po pripisaní dát");
              } else {
                commit("ADD_USER_INFO_VLASTNENE_ZAPISY", null);
                console.log(JSON.stringify(state.user) + "bez pripísania dát");
              }

              state.prihlasenyStav++;
              return;
            case "Request failed":
              switch (response.data.data) {
                case "Not logged in":
                  console.log(response.data.data);
                  return;
                case "Cart is empty":
                  state.SnackBarText = "Váš košík je prázdny";
                  return;
                default:
                  // alert("Neznáma odpoveď");
                  // state.SnackBarText =
                  //   "Vaše vlastnené produkty neboli správne načítané";
                  return;
              }
            default:
              // alert("Neznáma odpoveď");
              // state.SnackBarText =
              //   "Vaše vlastnené produkty neboli správne načítané";
              return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  modules: {},
});
